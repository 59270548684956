export const listImages = [{
  src: "https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80",
  imgAlt: "Main",
  imgTitle: "Main",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
];

export const Article1 = {
  id: '11123233-2342342-23234-23424353',
  url: '/articles/11123233-2342342-23234-23424353',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: '/assets/placeholder.jpg',
  alias: '/blog/community-news/something-test',
  internal: '/node/1561',
  nid: 1561,
  internalId: 1561,
  body: "<p>For the second year in a row, Potnets has received the coveted designation of Best Real Estate</p>\r\n",
  categoryTerm: [{name: "Selling"}],
  categoryLink: "/blog/selling",
  slides: listImages,
  videoOrig: "",
  title: "Potnets VOTED BEST REAL ESTATE COMPANY",
  created: "Thursday, July 22, 2021",
};

export const Article2 = {
  id: '11123233-2342342-23234-777788888',
  url: '/articles/11123233-2342342-23234-777788888',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: '/assets/placeholder.jpg',
  alias: '/blog/community-news/some-other2',
  internal: '/node/789',
  nid: 789,
  internalId: 789,
  body: "<p>The Atlantic hurricane season runs from June 1st to the end of November. In Florida strong</p>\r\n",
  categoryTerm: [{name: "Selling"}],
  category_link: "/blog/selling",
  slides: listImages,
  videoOrig: "",
  title: "ARE YOU PREPARED TO WEATHER THE STORM?",
  created: "Tuesday, June 29, 2021",
};

export const Article3 = {
  id: '11123233-2342342-4444444-23424353',
  url: '/articles/11123233-2342342-4444444-23424353',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: '/assets/placeholder.jpg',
  alias: '/listing/buy/8237648276',
  internal: '/node/23332',
  nid: 23332,
  internalId: 23332,
  body: "<p>Looking for a lush, gorgeous yard, but not happy about guzzling tons of water? The following tips</p>\r\n",
  categoryTerm: [{name: "Home Improvements"}],
  category_link: "/blog/home-improvements",
  slides: listImages,
  videoOrig: "",
  title: "7 Water-Saving Tips for Your Yard",
  created: "Thursday, May 27, 2021",
};

export const Testimonial1 = {
  body: "I just wanted to take the opportunity to share with you the excitement that Bob and I have experienced with our new home. I think the pride we feel when we drive through the community then go inside our new home will last for a very long time.",
  name: "Bob & Connie A.",
  location: "Conshohocken, PA",
  title: 'So Excited!',
};

export const Testimonial2 = {
  body: "We have been\n" +
    "                working with Potnets since 2010. We have very good\n" +
    "                experiences with them. When we started out we were renters. We\n" +
    "                decided to purchase a home with Craig's help. I was being to\n" +
    "                picky with the houses he showed us and he then suggested to\n" +
    "                build a house since we had the time. Craig helped us every step\n" +
    "                of the way and made building a house easier. We were very\n" +
    "                disappointed when we got orders to move. Without hesitation we\n" +
    "                called Craig to help us manage our property. It is nice to know\n" +
    "                our house is in good hands and we do not have to worry. They\n" +
    "                have high standards when it comes time to move out that I love.\n" +
    "                Thank you Potnets for taking good care of my home. I have\n" +
    "                recommended clients to them without any hesitation.",
  name: "Bob & Connie A.",
  location: "Conshohocken, PA",
  title: 'So Excited!',
};

export const Testimonial3 = {
  body: "When we moved to\n" +
    "                FishHawk Ranch, we were very concerned about the timing of\n" +
    "                selling our house, finding a place to rent and then acclimating\n" +
    "                to life in general here in Florida. Not only did Potnets\n" +
    "                get us in a rental for our family that fed into the school we\n" +
    "                wanted with short-notice, but they helped us get acclimated to\n" +
    "                life as well by suggesting places to go for food and services,\n" +
    "                and specific vendors to use for life's unexpected turns.They\n" +
    "                were extremely responsive and fair as a property manager, and we\n" +
    "                liked them so much that we asked them to embark on finding, and\n" +
    "                in fact, building a new house. They are great people and felt\n" +
    "                like family.",
  name: "Bob & Connie A.",
  location: "Conshohocken, PA",
  title: 'So Excited!',
};

export const TieredCta1 = {
  title: 'BASIC',
  link: "https://www.potnets.com",
  btnColor: 'success.main',
  hreftarget: '_blank',
  bgColor: '#0071B3',
  body: "<h3>WE FIND THE TENANT</h3><p>$ One Month's Rent</p><small>*Minimum Rates Apply. Contact Us for Details</small><p>WHAT'S INCLUDED:</p><p>Professional Photgraphy & Virutal Tour<br />Featured Listing on potnets.com<br />Premium MLS Entry<br />Top Placement on Zillow <br />Yard Sign & Flyer Box <br />Thorough Tenant Screening <br />Lease Preparation Drafted by Flordia Attorney",
};

export const TieredCta2 = {
  title: 'CONCIERGE PRO',
  link: "https://www.potnets.com",
  btnColor: 'primary',
  bgColor: 'success.main',
  body: "<h3>WE HANDLE EVERYTHING</h3><p>$ One Month's Rent* <br/> + 10% Monthly Rent Rate*<br />(Additional Tenants $995)<br />(Lease Renewals $250)</p><small>*Minimum Rates Apply. Contact Us for Details</small><p>WHAT'S INCLUDED:</p><p>All Basic Services, PLUS<br/>Monthly Rent Collection<br/>Detailed Monthly Accounting<br/>HOA Paperwork Submission<br />Service Coordination & Invoice Payment (i.e.Yard, Pool, etc.) <br/> We Handle All Maintenance Requests Tenant Check - In / Check - Out Procedure <br /> Including Extensive Photos <br /> Eviction Processing(Eviction Fees Additional) <br /> Property Restoration After the Tenant Vacates <br /> Year - End Tax Statements <br /> Prep & Send 1099’ s to Vendor & IRS <br/> No Monthly Vacancy Fee </p>",
};

export const TieredCta3 = {
  title: 'INVESTOR PLUS',
  link: "https://www.potnets.com",
  btnColor: 'default',
  bgColor: 'grey.300',
  btnLabel: 'Check me out',
  body: "<h3>GROW YOUR PORTFOLIO</h3><p>Contact Us for Pricing</p><p>WHAT'S INCLUDED:</p><p>Perfect for investors with 5 or more properties. Receive our Full Concierge Pro Level Property Management Services for each property, at a discounted rate!</p><p>Need help finding the perfect investment property to purchase? Our Team has extensive experience in vetting homes that will maximize your Return on Investment.</p>",
};

export const ExpandCta1 = {
  title: 'PRE APPROVAL',
  body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at ipsum enim. Suspendisse varius sem non accumsan dictum. Duis eget ligula ultrices, pulvinar odio sed, mattis dui. Proin eget lorem posuere, euismod nunc ac, gravida eros. Aenean gravida nulla nec posuere imperdiet. Aenean vel tellus nibh. Proin non auctor neque. Nullam sed nibh nunc. Morbi lobortis ex sed odio finibus dignissim a a risus. Aenean nec dolor nibh. Sed a mollis elit. Nam consequat metus leo, vel laoreet felis accumsan vulputate. Fusce augue elit, semper ac sem quis, rutrum sagittis neque. Donec molestie non urna id venenatis.",
  number: 1,
};

export const ExpandCta2 = {
  title: 'SEARCH',
  body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at ipsum enim. Suspendisse varius sem non accumsan dictum. Duis eget ligula ultrices, pulvinar odio sed, mattis dui. Proin eget lorem posuere, euismod nunc ac, gravida eros. Aenean gravida nulla nec posuere imperdiet. Aenean vel tellus nibh. Proin non auctor neque. Nullam sed nibh nunc. Morbi lobortis ex sed odio finibus dignissim a a risus. Aenean nec dolor nibh. Sed a mollis elit. Nam consequat metus leo, vel laoreet felis accumsan vulputate. Fusce augue elit, semper ac sem quis, rutrum sagittis neque. Donec molestie non urna id venenatis.",
  number: 2,
};

export const ExpandCta3 = {
  title: 'CLOSE',
  body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at ipsum enim. Suspendisse varius sem non accumsan dictum. Duis eget ligula ultrices, pulvinar odio sed, mattis dui. Proin eget lorem posuere, euismod nunc ac, gravida eros. Aenean gravida nulla nec posuere imperdiet. Aenean vel tellus nibh. Proin non auctor neque. Nullam sed nibh nunc. Morbi lobortis ex sed odio finibus dignissim a a risus. Aenean nec dolor nibh. Sed a mollis elit. Nam consequat metus leo, vel laoreet felis accumsan vulputate. Fusce augue elit, semper ac sem quis, rutrum sagittis neque. Donec molestie non urna id venenatis.",
  number: 3,
};

export const LocationBlockTeaser1 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/appolo-beach',
  internal: '/taxonomy/term/11',
  internalId: 11,
  // imgAlt: "Alt text",
  // imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/styles/large/public/gallery_city/apollobeachboating.jpg?itok=2kdbN-Je',
  title: 'Apollo Beach',
  body: '<p>Known for being a carnival town, Gibsonton\n' +
    '                  is full of character. GibTown, as it is known among locals,\n' +
    '                  has become the famous wintering town of many sideshow\n' +
    '                  performers and is home to the International Independent\n' +
    '                  Showmen\'s Museum. Gibsonton offers it\'s residents easy access\n' +
    '                  to shopping and area attractions with its convenient location\n' +
    '                  between I-75 and Hwy 41 and has become a fast-growing suburb\n' +
    '                  for Tampa commuters. Bordered on the North by the Alafia River\n' +
    '                  with smaller creeks and tributaries, Gibsonton is a haven for\n' +
    '                  those that love water recreation.</p>',
  slides: [{
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
    imgAlt: "Main",
    imgTitle: "Main",
  },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
      imgAlt: "Test",
      imgTitle: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
      imgAlt: "Test",
      imgTitle: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
      imgAlt: "Main",
      imgTitle: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
      imgTitle: "Test",
      imgAlt: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
      imgTitle: "Test",
      imgAlt: "Test",
    },
  ]
};

export const LocationBlockTeaser2 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/brandon',
  internal: '/taxonomy/term/10',
  internalId: 10,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/styles/large/public/gallery_city/brandonrestaurants.jpg?itok=Zr_XU6B0',
  title: 'BRANDON',
  body: 'Brandon is largely residential and many of Brandon residents commute to Tampa or the other surrounding cities. Brandon is ideal for this type of commute because of its easy access to two key interstates, I-4 and I-75.',
  slides: [],
};

export const LocationBlockTeaser3 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/dover',
  internal: '/taxonomy/term/14',
  internalId: 14,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.potnets.com/sites/default/files/styles/large/public/gallery_city/sydney_dover_park.jpg?itok=DAbPR5Fn',
  title: 'DOVER',
  body: 'Dover is a small unincorporated town located east of Tampa Bay and in recent years has experienced significant growth as residents continue to spread outside of the city.',
  slides: [{
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
    imgAlt: "Main",
    imgTitle: "Main",
  },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
      imgAlt: "Test",
      imgTitle: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
      imgAlt: "Test",
      imgTitle: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
      imgAlt: "Main",
      imgTitle: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
      imgTitle: "Test",
      imgAlt: "Test",
    },
    {
      src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
      imgTitle: "Test",
      imgAlt: "Test",
    },
  ]
};

export const locationImages = [{
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
    imgAlt: "Main",
    imgTitle: "Main",
  },
  {
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
    imgAlt: "Main",
    imgTitle: "Test",
  },
  {
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://www.potnets.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
    imgTitle: "Test",
    imgAlt: "Test",
  },
];


export const Banner1 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/appolo-beach',
  internal: '/taxonomy/term/11',
  internalId: 11,
  // imgAlt: "Alt text",
  // imgTitle: "Title text",
  imageOrig: 'https://www.baywoodgreens.com/sites/default/files/styles/content_slideshow_escapia/public/slideshows/Clubhouse%20IMG_2664%20%281%29_0.jpg',
  subtitle: 'IF BEAUTY IS IN THE EYE OF THE BEHOLDER,',
  title: 'Then Behold & Baywood Greens',
  bannerSize: 'sm',
  ctaLinks: [
    {
      second: "/",
      first: "EXPLORE BAYWOOD",
    },
  ]
};
export const Banner2 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/brandon',
  internal: '/taxonomy/term/10',
  internalId: 10,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.baywoodgreens.com/sites/default/files/styles/content_slideshow_escapia/public/slideshows/Clubhouse%20IMG_2664%20%281%29_0.jpg',
  subtitle: 'IF BEAUTY IS IN THE EYE OF THE BEHOLDER,',
  title: 'Then Behold Baywood Greens',
  body: 'Brandon is largely residential and many of Brandon residents commute to Tampa or the other surrounding cities. Brandon is ideal for this type of commute because of its easy access to two key interstates, I-4 and I-75.',
  slides: [],
  bannerSize: 'md',
  ctaLinks: [
    {
      second: "/",
      first: "EXPLORE BAYWOOD",
    },
  ]};

export const Banner3 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/dover',
  internal: '/taxonomy/term/14',
  internalId: 14,
  imageOrig: 'https://www.baywoodgreens.com/sites/default/files/styles/content_slideshow_escapia/public/slideshows/Clubhouse%20IMG_2664%20%281%29_0.jpg',
  subtitle: 'A WORLD CLASS COMMUNITY AT THE BEACH',
  title: 'Home & Vacation All In One',
  body: 'Dover is a small unincorporated town located east of Tampa Bay and in recent years has experienced significant growth as residents continue to spread outside of the city.',
  bannerSize: 'lg',
  ctaLinks: [
    {
      second: "/",
      first: "EXPLORE BAYWOOD",
    },
    {
      second: "/",
      first: "PROPERTY SEARCH",
    },
  ]};


export const Banner4 = {
  id: '11123233-2342342-4444444-23424353',
  alias: '/city/dover',
  internal: '/taxonomy/term/14',
  internalId: 14,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  subtitle: 'IF BEAUTY IS IN THE EYE OF THE BEHOLDER,',
  title: 'Then Behold Baywood Greens',
  imageOrig: 'https://www.baywoodgreens.com/sites/default/files/styles/content_slideshow_escapia/public/slideshows/Clubhouse%20IMG_2664%20%281%29_0.jpg',
  body: 'Dover is a small unincorporated town located east of Tampa Bay and in recent years has experienced significant growth as residents continue to spread outside of the city.',
  bannerSize: 'xl',
  ctaLinks: [
    {
      second: "/",
      first: "EXPLORE BAYWOOD",
    },
    {
      second: "/",
      first: "PROPERTY SEARCH",
    },
    {
      second: "/",
      first: "TOUR OUR COURSES",
    },
  ]
};

export const Banner5 = {
  id: '11123233-2342342-4444444-23424353',
  internalId: 14,
  video: {videoEmbed: 'https://player.vimeo.com/video/707006438?&autoplay=1&loop=1&mute=1'},
  showLogo: 1,
  bannerSize: 'video',
};

export const Banner6 = {
  id: '11123233-2342342-4444444-23424353',
  internalId: 14,
  videoHosted: '/video/intro.mp4',
  videoHostedEnd: '/video/ending.mp4',
  showLogo: 1,
  bannerSize: 'video',
};

export const CourseSlide1 = {
  id: '234345634-644564-23586755-23424353',
  slides: listImages,
  internalId: 345,
  imgAlt: "Alt text",
  imgTitle: "Image Title",
  imageOrig: '/images/placeholder/image 46.png',
  ttlYards: 345,
  holePar: 4,
  golfWidth: 37,
  golfDepth: 30,
  title: 'Woodside 1st Green',
  body: 'This short par 4 requires precision from the tee to avoid the fairway sand and grass bunkers. A long iron or a fairway wood leaves the player with a short iron to the two-tiered green protected by bunkers on the front left and back right.',
  video: {
    videoEmbed: '/assets/placeholder_video.mp4',
  }
};

export const CourseSlide2 = {
  id: '234345634-45674-23586755-23424353',
  internalId: 745,
  // slides: listImages,
  imgAlt: "Alt text",
  imgTitle: "Image Title",
  imageOrig: 'https://images.unsplash.com/photo-1433800881591-a31e7bff3f22?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80',
  ttlYards: 745,
  holePar: 4,
  golfWidth: 37,
  golfDepth: 30,
  title: 'Woodside 1st Green',
  body: 'This short par 4 requires precision from the tee to avoid the fairway sand and grass bunkers. A long iron or a fairway wood leaves the player with a short iron to the two-tiered green protected by bunkers on the front left and back right.',
};
