// import Head from 'next/head';
import _ from "lodash";
import {
  getIndividualPageData,
  getPageInfo,
  parseParagraphReact
} from "@/api/global";
import Typography from "@material-ui/core/Typography";
import ReactPlaceholder from 'react-placeholder';
import dynamic from "next/dynamic";
const SlickComponentLibs = dynamic(() => import('../components/CarouselComponentSlickStyles'));
import Banner from '@/components/03_organisms/Banner/index.js';
import {Banner6} from "@/helpers/nodes_data";

export async function getServerSideProps(context) {
  let initialProps = {
    statusCode: 200,
  };

  let data = null;
  // Homepage NID in Drupal:
  const nid = 1;

  try {
    if (nid && !isNaN(nid)) {
      // Listing data about currently opened listing.
      data = await getPageInfo(`/baywoodgreens-homepage`);

      if (data && data.page?.jsonapi) {
        const node_data = await getIndividualPageData(data.page.jsonapi.individual);
        if (node_data) {
          initialProps = {...initialProps, ...node_data};
        }
      }

      // Merge initially defined props with response from the backend.
      initialProps = {...initialProps, ...data};
    }
    if (!data) {
      return {
        notFound: true,
      }
    }
  }
  catch (e) {
    console.warn('HOMEPAGE retrieval error:', e);
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side Rendering, we want the server to throw the
    // correct error code.
    // if (res) {
    //   res.statusCode = 500;
    // }
  }

  return {
    props: {...initialProps}, // will be passed to the page component as props
    // notFound: true, // https://github.com/vercel/next.js/discussions/11862,
    // https://stackoverflow.com/questions/67946219/throw-new-errorfailed-to-load-static-props-when-setting-fallback-true-i/67946220#67946220
  }
}

export default function Home(props) {
  let node_data, body, body_json, para, paraTop;
  if (typeof document !== 'undefined') {
    document?.body?.classList?.add('homepage');
  }

  if (props) {
    node_data = props?.page_data;
    if (node_data) {
      ({body, body_json, para, paraTop} = node_data);
    }
  }

  return (
    <div>
      <main>
        <SlickComponentLibs/>
        {!node_data &&
        <>
          <Typography variant="h1" className={`page-title`}>Welcome to Baywoods!</Typography>
          <Typography component={`p`}>You’ll feel it the moment you enter the community. The tree lined streets, lush landscaping and brick walkways. With its magnificent Clubhouse as it’s centerpiece, the feeling is traditional and memorable. Then there’s the world class Baywood Greens golf course that winds it way through the community creating backyard vistas like nothing else around. And if that weren’t enough, there’s marinas with boat slips with access to Rehoboth Bay and the Atlantic Ocean, beaches, restaurants and shopping all just minutes away.</Typography>
        </>
        }
        <Banner node={Banner6}/>

        {/*{paraTop?.length > 0 &&*/}
        {/*<Typography paragraph key={`top-lvl-para`} className={`alignfull`} component={`div`}>*/}
        {/*  {*/}
        {/*    paraTop.map((para_ent, key) => {*/}
        {/*      return parseParagraphReact(key, para_ent);*/}
        {/*    })*/}
        {/*  }*/}
        {/*</Typography>*/}
        {/*}*/}

        {body?.length > 0 && _.isEmpty(body_json) &&
        <ReactPlaceholder type='text' rows={40} ready={body}
                          showLoadingAnimation={true}>
          <Typography paragraph component={`div`}>
            <Typography component={`div`} dangerouslySetInnerHTML={{__html: body}}></Typography>
          </Typography>
        </ReactPlaceholder>
        }
        {para?.length > 0 &&
          <div>
            <Typography paragraph key={`main-lvl-para`} component={`div`}>
              {
                para.map((para_ent, key) => {
                  return parseParagraphReact(key, para_ent);
                })
              }
            </Typography>
          </div>
        }
      </main>
    </div>
  )
}
